// store.js
import { persistStore, persistReducer } from 'redux-persist';
import { configureStore, compose, applyMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // o cualquier otro motor de almacenamiento
import persistorStore from 'redux-persist/es/persistStore';

// reducers/counterReducer.js
import { combineReducers } from '@reduxjs/toolkit';

import snackBar from './slice/snackBar';
import modules from './slice/modules';
import categories from './slice/categories';
import notifications from './slice/notifications';
import configuration from './slice/configuration';
import loadingMask from './slice/loadingMask';
import auth from './slice/auth';
import { verifyAuth } from './slice/auth';

const rootReducer = combineReducers({
    snackbar: snackBar,
    modules: modules,
    categories: categories,
    Notifications: notifications,
    auth: auth,
    loadingMask: loadingMask,
    configurations: configuration
});

const persistConfig = {
  key: 'root', // Clave para el almacenamiento
  storage, // Motor de almacenamiento
  blacklist: ['loadingMask'], // Reducers que no se almacenan
  // Otras opciones de configuración si es necesario
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Otras opciones de configuración de la tienda si es necesario
});

const persistor = persistorStore(store);

// store.dispatch(verifyAuth());

export {store, persistor};
