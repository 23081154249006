/**
 * @fileoverview AxiosHelper
 * Axios Helper
 * @version 1.0.0
 * @author César Paul Hernández Camacho
 * @date 2/11/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */

import Cookies from 'js-cookie';
import { store } from 'redux/index';
let currentURL = process.env.REACT_APP_API_SERVER;
getCurrentUrl();
const axios = require('axios');

function getCurrentUrl() {
    store.subscribe(() => {
        const reduxState = store.getState();
        if (reduxState.auth.isAuthenticated) {
            currentURL = reduxState.configurations.currentURL;
        }
    });
    currentURL = process.env.REACT_APP_API_SERVER;
}

const getToken = (data, roleId) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', data.username);
    params.append('password', data.password);
    params.append('client_Id', 'MyALD');
    params.append('client_secret', 'D2DCC2BB-1D09-43DC-8162-C0A9FF3FEF9C');
    switch (roleId) {
        case 0:
            params.append('scope', 'MyAldAPI openid');
            currentURL = process.env.REACT_APP_API_SERVER;
            break;
        case 3:
            params.append('scope', 'MyAldFMAPI openid');
            currentURL = process.env.REACT_APP_API_FM;
            break;
        case 4:
            params.append('scope', 'MyAldSupplierAPI openid');
            currentURL = process.env.REACT_APP_API_SUPPLIER;
            break;
        default:
            params.append('scope', 'MyAldAPI openid');
            currentURL = process.env.REACT_APP_API_SERVER;
            break;
    }

    return new Promise((resolve, reject) => {
        axios.post(
            `${process.env.REACT_APP_AUTH_SERVER}connect/token`,
            params
        )
            .then(async (response) => {
                if (await response) {
                    Cookies.set('token', await response.data.access_token);  
                    resolve(true);
                }
            })
            .catch(error => {
                reject(error);
            })
    })
}

const post = (url, data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${currentURL}${url}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(async response => {
                resolve(await response);
            })
            .catch(error => {
                reject(error);
            })
    })
}

const put = (url, data) => {
    return new Promise((resolve, reject) => {
        axios.put(
            `${currentURL}${url}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(async response => {
                resolve(await response);
            })
            .catch(error => {
                reject(error);
            })
    })
}

const get = (url) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${currentURL}${url}`,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(async response => {
                resolve(await response);
            })
            .catch(error => {
                reject(error);
            })
    })
}

const del = (url) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${currentURL}${url}`,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(async response => {
                resolve(await response);
            })
            .catch(error => {
                reject(error);
            })
    })
}

const deleteToken = () => {
    const credentials = JSON.parse(Cookies.get('userData'));
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_SERVER}/TokenRevocation`, {
            userName: credentials.username,
            token: Cookies.get("token")
        }).then(response => resolve(response))
            .catch(error => reject(error));
    }
    )
}

export default ({ getToken, post, put, del, get, deleteToken });