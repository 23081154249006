/**
 * @fileoverview Modal para validar la información del Presupuesto
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import React, { useEffect, useState } from 'react';
import Modal from './index';
import { Context } from '../Table';
import Table from '../Table/index'
import { Button, Grid, TextField } from '@mui/material';

import swal from 'sweetalert';
import { enqueueInfoSnackbar } from 'libs/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Rssr from 'libs/Rssr';

const PostData = {
    0: { Status: "AGREEMENT_STATUS_FM_AUTHORIZED", Comment: "Presupuesto autorizado" },
    1: { Status: "AGREEMENT_STATUS_FM_AUTHORIZATION_REJECTED", Comment: "Presupuesto rechazado" },
}

const ModalInformativo = ({
    OnEdit,
    OnComments,
    OnHistory,
    OnAttachment,
    DataAgreements,
    ...props
}) => {
    const dispatchRedux = useDispatch();
    const { state, dispatch } = React.useContext(Context);
    const [Data, setData] = useState({});
    const [Total, setTotal] = useState(0);
    // States Labours
    let Status = state.Agreements.filter((el) => el.AgreementId === state.AgreementData.AgreementId)[0]?.Status;
    let VersionId = state.Agreements.filter((el) => el.AgreementId === state.AgreementData.AgreementId)[0]?.VersionId;
    const [_TotalLabours, setTotalLabours] = useState(0);
    const regionId = useSelector(state => state.auth.loginData.regionId);
    const { t } = useTranslation('common');

    useEffect(() => {
        state.Agreements.filter((el) => {
            if (el.AgreementId === state.AgreementData.AgreementId) {
                setData(el);
            }
        }
        );

        // Total Parts
        if (state.AgreementData.Parts?.length > 0) {
            let total = 0;
            state.AgreementData.Parts.forEach(element => {
                total += element.TotalGross;
            });
            setTotal(total);
        }
        // Total Labours
        if (state.AgreementData.Labours?.length > 0) {
            let total = 0;
            state.AgreementData.Labours.forEach(element => {
                total += element.TotalGross;
            });
            setTotalLabours(total);
        }
    }, [state.AgreementData]);


    const onExit = (auth) => {

        if (Status === 'AGREEMENT_STATUS_FM_AUTHORIZED') return enqueueInfoSnackbar(dispatchRedux, t('ModuleAgreements.alerts.procesingRequest'));
        if (Status === 'AGREEMENT_STATUS_FM_REJECTED') return enqueueInfoSnackbar(dispatchRedux, t('ModuleAgreements.alerts.workingNewBudget'));
        if ((state.itemsSelected.length + state.itemsSelectedLabours.length) === 0) return enqueueInfoSnackbar(dispatchRedux, t('ModuleAgreements.alerts.selectItems'));

        let message = '';

        if (auth === 1) {
            message += `${t('ModuleAgreements.alerts.rejectedBudget.0')} ${state.AgreementData.AgreementId} ${t('ModuleAgreements.alerts.rejectedBudget.1')}`;
        }
        else {
            if ((state.itemsSelected.length + state.itemsSelectedLabours.length) === (state.AgreementData.Parts.length + state.AgreementData.Labours.length)) {
                message += `${t('ModuleAgreements.alerts.fullApprovalBudget.0')} (${state.AgreementData.AgreementId}) ${t('ModuleAgreements.alerts.fullApprovalBudget.1')} ${Rssr.formatPrice(Total)} ${t('ModuleAgreements.alerts.fullApprovalBudget.2')}`;
            }
            else {
                message += `${t('ModuleAgreements.alerts.partialBudget.0')}  \n\n ${t('ModuleAgreements.alerts.partialBudget.1')} `;
            }
        }

        swal({
            title: `AVISO`,
            text: message,
            icon: "info",
            buttons: {
                cancel: {
                    text: t('ModuleAgreements.Filters.cancel'),
                    value: null,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: t('generalText.accept'),
                    value: true,
                    visible: true,
                    closeModal: true
                }
            }
        }).then((res) => {
            if (res) {
                props.PutAgreement({
                    "Category": "AGREEMENT_STATUS_UPDATE",
                    "AgreementId": state.AgreementData.AgreementId,
                    "VersionId": VersionId,
                    "Status": PostData[auth].Status,
                    "Comment": PostData[0].Comment,
                    "Parts": state.itemsSelected.join(),
                    "Labour": state.itemsSelectedLabours.join()
                }, dispatch, VersionId);
            }
        });


    };

    const onSelectedItem = (value, section = 'parts') => { section === 'parts' ? dispatch({ type: 'setSelected', value }) : dispatch({ type: 'setSelectedLabours', value }) };

    return (
        <Modal open={state.ModalInfo} OnComments={() => OnComments(DataAgreements)} OnEdit={() => OnEdit(DataAgreements)} OnHistory={() => OnHistory(DataAgreements)} OnAttachment={() => OnAttachment(DataAgreements)}>
            <PerfectScrollbar>
                <section style={{
                    marginTop: 5,
                    padding: '0.5rem',
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Input label={t('ModuleAgreements.budget.id')} value={`${state.AgreementData.AgreementId}`} />
                        </Grid>
                        <Grid item xs={3}>
                            <Input label={t('ModuleAgreements.budget.plate')} value={`${Data.Plate}`} />
                        </Grid>
                        <Grid item xs={6}>
                            <Input label={t('ModuleAgreements.budget.salesForceCaseNumber')} value={`${state.AgreementData.SalesForceCaseNumber || ''}`} />
                        </Grid>
                        <Grid item xs={6}>
                            <Input label={t('ModuleAgreements.budget.vehicle')} value={`${Data.VhDescription}`} />
                        </Grid>
                        <Grid item xs={3}>
                            <Input label={t('ModuleAgreements.budget.km')} value={`${state.AgreementData.Mileage}`} />
                        </Grid>

                        <Grid item xs={3}>
                            <Input label={t('ModuleAgreements.budget.vin')} value={`${Data.Vin}`} />
                        </Grid>
                        <Grid item xs={6}>
                            <Input label={t('ModuleAgreements.budget.driver')} value={`${Data.DriverName || ' '}`} />
                        </Grid>
                        <Grid item xs={6}>
                            <Input label={t('ModuleAgreements.budget.customer')} value={`${Data.CustomerName || ' '}`} />
                        </Grid>
                        <Grid item xs={6}>
                            <Input label={t('ModuleAgreements.budget.supplier')} value={`${Data.SupplierNumber} - ${Data.SupplierName}`} />
                        </Grid>
                        <Grid item xs={6}>
                            <Input label={t('ModuleAgreements.budget.workshop')} value={'Continental'} />
                        </Grid>
                        {
                            regionId === 3 && (
                                <Grid item xs={6}>
                                    <Input label={t('ModuleAgreements.budget.maintenance')} value={state.AgreementData.Maintenances?.length > 0 ? state.AgreementData.Maintenances[0].CrepCodeDescription || state.AgreementData.Maintenances[0].Description : ''} />
                                </Grid>
                            )
                        }
                    </Grid>
                    <Table data={state.AgreementData.Parts} onSelectedItem={onSelectedItem} section={'parts'} checked={Status !== "AGREEMENT_STATUS_AWAITING_FM_AUTHORIZATION"} />
                    {state.AgreementData.Labours?.length > 0 && <Table data={state.AgreementData.Labours} onSelectedItem={onSelectedItem} section={'labour'} checked={Status !== "AGREEMENT_STATUS_AWAITING_FM_AUTHORIZATION"} />}
                    <section style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>

                        <Button
                            disabled={Status !== "AGREEMENT_STATUS_ASSIGNED" && Status !== "AGREEMENT_STATUS_AWAITING_FM_AUTHORIZATION"}
                            onClick={() => onExit(1)}
                            variant='outlined'
                            style={{ marginRight: 15 }}
                            sx={{
                                border: '1px solid #828282',
                                borderRadius: 18,
                                color: '#828282',
                                "&:hover": {
                                    borderColor: '#828282',
                                    color: '#828282',
                                },

                            }}
                        >
                            {t('ModuleAgreements.budget.rejected')}
                        </Button>
                        <Button
                            disabled={Status !== "AGREEMENT_STATUS_ASSIGNED" && Status !== "AGREEMENT_STATUS_AWAITING_FM_AUTHORIZATION"}
                            onClick={() => onExit(0)}
                            variant='outlined'
                            sx={{
                                border: '1px solid #644AF3',
                                borderRadius: 18,
                                color: '#644AF3',
                                "&:hover": {
                                    borderColor: '#644AF3',
                                    color: '#644AF3',
                                },
                            }}
                        >
                            {
                                (state.itemsSelected?.length + state.itemsSelectedLabours?.length) === (state.AgreementData?.Parts?.length + state.AgreementData.Labours?.length) ? t('ModuleAgreements.budget.fullAproval') : t('ModuleAgreements.budget.partialAproval')
                            }
                        </Button>
                    </section>
                </section>
            </PerfectScrollbar>
        </Modal>
    )
}

export default ModalInformativo;

const Input = ({ value, label }) => {
    return (
        <TextField
            fullWidth
            label={label}
            variant='standard'
            value={value}
            disabled={true}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                endAdornment: label === 'KM:' ? (
                    <div position="end">
                        KM
                    </div>
                ) : null,
            }}
            sx={{
                // CAmbiar el estilo del cuando esta deshabilitado
                "&.MuiFormControl-root": {
                    "&.MuiTextField-root": {
                        borderBottom: '1px solid #828282',
                    }
                },
                "& .MuiInput-input.Mui-disabled": {
                    WebkitTextFillColor: '#828282',
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                    color: '#828282'
                }


            }}
        />
    )
}

