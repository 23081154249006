/**
 * @fileoverview Admin
 * @version 1.1.0
 * @author César Paul Hernández Camacho
 * @date 24/02/2021
 * @copyright 2020 Industrias RESSER S.A de C.V
 *
 */

import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import axios from 'libs/AxiosHelper';
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";

import AcceptCookies from 'components/AcceptCookies/AcceptCookies';
//redux
import { setUserDataR, setProfileInfo, setShowCookies, addCategories, hideSidebarMask, addModules, showSidebarMask } from 'redux/slice';
import { useSelector, useDispatch } from 'react-redux';

//Screen Components
import UserProfile from "views/UserProfile/UserProfile";
import UserSettings from "views/UserProfile/UserSettings";
import DocumentNotification from "views/Notifications/DocumentNotification";
import SAT from 'views/SAT/SAT';
//Components
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
//logo

import { enqueueErrorSnackbar } from 'libs/Snackbar';
import { useTranslation } from 'react-i18next';
import Sidebar from "components/sidebar/Sidebar";

let ps;

const useStyles = makeStyles(styles);

let lastModule = "";

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  const [t] = useTranslation('common');
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const dispatch = useDispatch();
  const [links, setLinks] = useState('');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [SidebarCollapse, setSidebarCollapse] = React.useState(false);
  const modules = useSelector(state => state.modules.modules);
  const getProfileInfo = useSelector(state => state.configurations.getProfileInfo);
  const showFixedDrawer = useSelector(state => state.configurations.showFixedDrawer);
  const seenCookies = useSelector(state => state.configurations.seenCookies);
  const SignedPolicies = useSelector(state => state.auth.loginData.SignedPolicies);
  
  useEffect(() => {
    getPrivacyPolicies();
    if(modules.length === 0){
      getCategoryes();
      getModules();
    }
  return () => {}
}, [])

  //
  useEffect(() => {
    if ((!seenCookies) || (localStorage.getItem("NewUserAccount") !== localStorage.getItem("userAccount"))) {
      dispatch(setShowCookies(true));
    }
    else {
      dispatch(setShowCookies(false));
    }
    localStorage.setItem("userAccount", localStorage.getItem("NewUserAccount"));

}, [])

/**
    * @title getCategoryes (Async / Await)
    * @description Función Asincrona para el GET de categorias, peticion para la obtencion de las categorias relacionadas co el usuario.
   */

const getCategoryes = async () => {
  axios.get("modulecategoriesfmv2")
      .then(async res => {
          if (res.data.success) {
              const data = res.data.items.sort(function (a, b) {
                  if (a.order > b.order) {
                      return 1
                  } else {
                      return -1
                  }
              });
              dispatch(addCategories(data));
              dispatch(hideSidebarMask());
              return true;
          } else {
              enqueueErrorSnackbar(dispatch, t('login.errors.auth.3'));
          }
      })
      .catch(error => {
          enqueueErrorSnackbar(dispatch, t('login.errors.auth.3'));
      });
}

/**
* @title getModules (Async / Await)
* @description Función Asincrona para el GET de modulos, peticion para la obtencion de modulos relacionados con los usuario.
*/

const getModules = async () => {
  dispatch(showSidebarMask());
  axios.get("ModulesFMV2")
      .then(async res => {
          if (res.data.success) {
              dispatch(addModules(res.data.items));
              return true;
          } else {
              enqueueErrorSnackbar(dispatch, t('login.errors.auth.3'));
          }
      })
      .catch(error => {
          enqueueErrorSnackbar(dispatch, t('login.errors.auth.3'));
      });
}



  const getPrivacyPolicies = () => {
    axios.get('PoliciesAndTerms').then(async response => {
      if (await response.data.success) {
        if (response.data.policies.length > 0) {
          let policiesAux = response.data.policies.find(
            e => e.policyTypeId === 1,
          );
          let termsAux = response.data.policies.find(
            e => e.policyTypeId === 3,
          );
          let cookiesAux = response.data.policies.find(
            e => e.policyTypeId === 2,
          );
          setLinks([
            policiesAux === undefined ? '' : policiesAux.data,
            cookiesAux === undefined ? '' : cookiesAux.data,
            termsAux === undefined ? '' : termsAux.data
          ])
        }
      }
    });
  };

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (lastModule === "") {
          lastModule = prop.layout + prop.path;
        }
        var hasModule = {};
        if (modules.length > 0) {
          hasModule = modules.find(e => e.id === prop.id);
        }
        if ((modules.length === 0) || (hasModule !== undefined)) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}

      <Route
        path="/admin/home"
        component={routes[0].component}
      />
      <Route
        path="/admin/userprofile"
        component={UserProfile}
      />
      <Route
        path="/admin/usersettings"
        component={UserSettings}
      />
      <Route
        path="/admin/Document-Notification"
        component={DocumentNotification} />
      <Route
        path="/admin/SAT"
        component={SAT}
      />
      <Redirect from="/admin" to="/admin/home" />
    </Switch>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerCollapseToggle = () => {
    setSidebarCollapse(!SidebarCollapse);
  };

  const getRoute = () => {
    return window.location.pathname === "/admin/home";
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const getProfileData = async () => {
    if (!getProfileInfo) {
      await axios.get('FMProfile')
        .then(async response => {
          if (await response.data.success) {
            dispatch(setUserDataR(response.data.profileInfo));
            dispatch(setProfileInfo(true));
          }
        });
    }
  }

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    getProfileData();
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "auto";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      { !SignedPolicies && <AcceptCookies links={links} /> }
      <Sidebar
        routes={routes}
        handleDrawerCollapseToggle={handleDrawerCollapseToggle}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        link={links[0]}
        SidebarCollapse={SidebarCollapse}
        setSidebarCollapse={setSidebarCollapse}
        {...rest}
      />
      <div className={(showFixedDrawer) ?
        (SidebarCollapse) ?
          classes.panelToggleDrawerCollapse :
          classes.panelToggleDrawer :
        classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map} style={{width: '95%', marginLeft: 30}}>{switchRoutes}</div>
        )}
      </div>
    </div>
  );
}
