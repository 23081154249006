/**
 * @fileoverview Admin NavbarLinks
 * @version 2.1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 27/03/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
//Styles
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
//redux
import { logoutUser, deleteModules, setProfileInfo, setLanguage } from 'redux/slice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { enqueueSuccessSnackbar } from 'libs/Snackbar';

//Icons
import IconMenu from "../../assets/img/Icons/menu.svg";

import { IdiomaIcon, AjustesIcon, LogOutIcon, PerfilIcon, NotificationIcon, NotificationActiveIcon } from "../../assets/img/Icons/Icons";
import { PopperMenu, PopperLenguage, PopperOptions } from "components/Poppers/Poppers";
import { PopperNotifications } from "components/Poppers/Poppers";
import axios from "../../libs/AxiosHelper";
import Cookies from "js-cookie";

const useStyles = makeStyles(styles);

/**
 * AdminNavbarLinks
 * @returns Fragment
 */

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const [openProfileMobil, setOpenProfileMobil] = React.useState(null);
  const [openNotifications, setOpenNotifications] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [Option, setOption] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  //redux state
  const dispatch = useDispatch();
  const language = useSelector((state) => state.configurations.language);
  const arrayNotifications = useSelector((state) => state.Notifications.notifications);

  //validar array de notificaciones para saber si hay algun parametro de readStatus sea true
  const validateNotifications = arrayNotifications == null ? 0 :
    arrayNotifications.filter((item) => item.readStatus === false);

  let history = useHistory();

  const [t, i18n] = useTranslation('common');

  /**
   * @title goToProfileUser
   * @description Funcion para la navegacion a la configuracion de usuario
   */

  const goToProfileUser = () => {
    history.push("/admin/userprofile");
    setOpenProfile(null);
  }

  /**
   * @title goToProfileSetting
   * @description Funcion para la navegacion a la configuracion General
   */

  const goToProfileSettings = () => {
    history.push("/admin/usersettings");
    setOpenProfile(null);
  }

  /**
   * @title handleClickLenguage
   * @description Funcion auxiliar para abrir el Popper de Configuracion de Lenguaje
   * @param {target} event 
   */

  const handleClickNotifications = event => {
    setOpenProfile(null);
    if (openNotifications && openNotifications.contains(event.target)) {
      setOpenNotifications(null);
    } else {
      setOpenNotifications(event.currentTarget);
    }
  };

  /**
   * @title handleClickLenguage
   * @description Funcion auxiliar para abrir el Popper de Configuracion de Lenguaje
   * @param {target} event 
   */

  const handleClickLenguage = event => {
    setOpenNotifications(null);
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  /**
   * @title handleClickProfileMobil
   * @description Funcion auxiliar para abrir el Popper de opcion en dispositivos Pequeños
   * @param {target} event 
   */

  const handleClickProfileMobil = event => {
    if (openProfileMobil && openProfileMobil.contains(event.target)) {
      setOpenProfileMobil(null);
    } else {
      setOpenProfileMobil(event.currentTarget);
    }
  };

  /**
   * @title handlePopper
   * @description Funcion auxiliar para abrir Popper Informativo
   */

  const handlePopper = (Open, ruta) => (event) => {
    setOption(
      ruta === 1 ? t("NavBar.language") :
        ruta === 2 ? t("NavBar.settings") :
          ruta === 3 ? t("NavBar.logout") :
            ruta === 4 ? t("NavBar.profile") :
              ruta === 5 ? t("NavBar.notifications") :
                ""
    )
    setAnchorEl(event.currentTarget);
    setOpen(Open);
  };

  /**
   * @title onChangelanguage
   * @description Función encargada de realizar la actualización del idioma en el portal
   * @param {string} value (Idioma seleccionado)
   */

  const onChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    enqueueSuccessSnackbar(dispatch, t('profileConfiguration.account.success.1'));
    dispatch(setLanguage(value));
    setOpenProfile(null);
  }

  /**
   * @title onLogOut
   * @description Funcion auxiliar para la solicitud del LogOut del Perfil
   */

  const onLogOut = () => {
    axios.deleteToken().then(
      _response => {
        if(_response.data.success === true){
          dispatch(logoutUser());
          dispatch(setProfileInfo(false));
          dispatch(deleteModules());
          localStorage.removeItem('jwttokenmyald');
        }
      }
    )
    .catch(error => console.log(error))
  }

  /**
   * @title AdminNavbarLinks
   * @description Funcion encargada de retornar el componente de la barra de navegacion
   * @returns Fragment
   * @version 1.0.0
   * @author Carlos Emilio Blanco Lopez
   */

  return (
    <>
      <div style={{ display: "flex" }} className="navbar_links">
        {
          validateNotifications.length > 0 ?
            <NotificationActiveIcon
              onClick={handleClickNotifications}
              onMouseEnter={handlePopper(true, 5)}
              onMouseLeave={handlePopper(false, 0)}
            />
            :
            <NotificationIcon
              onClick={handleClickNotifications}
              onMouseEnter={handlePopper(true, 5)}
              onMouseLeave={handlePopper(false, 0)}
            />
        }
        <IdiomaIcon width={20} height={20}
          onClick={handleClickLenguage}
          onMouseEnter={handlePopper(true, 1)}
          onMouseLeave={handlePopper(false, 0)}
        />
        <PerfilIcon width={20} height={20}
          onClick={goToProfileUser}
          onMouseEnter={handlePopper(true, 4)}
          onMouseLeave={handlePopper(false, 0)} />
        <AjustesIcon width={20} height={20}
          onClick={goToProfileSettings}
          onMouseEnter={handlePopper(true, 2)}
          onMouseLeave={handlePopper(false, 0)} />
        <LogOutIcon width={20} height={20}
          onClick={onLogOut}
          onMouseEnter={handlePopper(true, 3)}
          onMouseLeave={handlePopper(false, 0)} />
      </div>
      <div className="navbar_menuMobil_button" onClick={handleClickProfileMobil}>
        <img src={IconMenu} className="navbar_menuMobil_img" />
      </div>
      {/* Poppers Menu Desplegable */}
      <PopperMenu
        open={Boolean(openProfileMobil)}
        anchorEl={openProfileMobil}
        handleClickLenguage={handleClickLenguage}
        goToProfileSettings={goToProfileSettings}
        goToProfileUser={goToProfileUser}
        onLogOut={onLogOut} />
      {/* Poppers Idiomas */}
      <PopperLenguage
        open={Boolean(openProfile)}
        anchorEl={openProfile}
        setOpenProfile={() => setOpenProfile(null)}
        language={language}
        onChangeLanguage={(value) => onChangeLanguage(value)} />
      {/* Notifications */}
      <PopperNotifications
        open={Boolean(openNotifications)}
        anchorEl={openNotifications}
        option={Option}
        onOpenNotification={() => setOpenNotifications(null)} />
      {/* Popper Options */}
      <PopperOptions
        open={open}
        anchorEl={anchorEl}
        option={Option} />
    </>
  );
}
